import React, { Fragment } from 'react';
import loadable from '@loadable/component';

import CoursesList from '../coursesList';
import Courses from './courses';

const Modalidad = loadable(() => import('./modalidad'));
const Pedagogia = loadable(() => import('./pedagogia'));
const Formacion = loadable(() => import('./formacion'));
// const FormacionCe = loadable(() => import('./coaching_ejecutivo'));
const Capacitaciones = loadable(() => import('./capacitaciones'));
const Testimonios = loadable(() => import('./testimonios'));
const Contacto = loadable(() => import('./contacto'));

const Sections = ({
  allSections,
  allCourses,
  allInfoSections,
  modality,
  allFeatures,
  allTestimonies,
  allServices,
  allImages,
}) => {
  console.log(modality);

  const { title, subtitle } = allInfoSections.edges[0].node;
  return (
    <Fragment>
      <Modalidad
        title={title}
        subtitle={subtitle}
        modality={modality}
      ></Modalidad>
      <Pedagogia
        allInfoSections={allInfoSections}
        allFeatures={allFeatures}
      ></Pedagogia>
      <Formacion allInfoSections={allInfoSections}></Formacion>
      <Courses
        allImages={allImages}
        allCourses={allCourses}
        allInfoSections={allInfoSections}
      />
      <Capacitaciones
        allInfoSections={allInfoSections}
        allServices={allServices}
      ></Capacitaciones>
      <Testimonios allTestimonies={allTestimonies}></Testimonios>
      <Contacto
        title="Contáctanos"
        subtitle="Estamos para responder tus consultas"
        from="contacto"
      ></Contacto>
    </Fragment>
  );
};
export default Sections;
