import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { primary } from '../../_Styles';

const MenuWraper = styled.div`
position:fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${primary};
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	z-index:999;
	
`;

export const Menu = ({ children }) => {
	return (
		<Fragment>
			<MenuWraper>{children}</MenuWraper>
		</Fragment>
	);
};
