import { Col, Row } from 'antd';
import React, { Fragment } from 'react';
import { filterImagesFluid } from '../../../util/filterImages';
import Card from '../../UIComponents/card';
const CourseItem = ({ image, title, description, slung }) => {


    return (
        <Fragment>

            <Col xs={24} sm={24} md={8}>
                <Card
                    image={image}
                    title={title}
                    description={description}
                    slung={slung}
                />
            </Col>


        </Fragment>
    );
};

export default CourseItem;
