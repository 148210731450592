import React from 'react';
import CoursesList from './coursesList';

const Courses = ({ allImages, allCourses, allInfoSections }) => {
  console.log(allImages);
  return (
    <CoursesList
      allImages={allImages}
      allCourses={allCourses}
      allInfoSections={allInfoSections}
    ></CoursesList>
  );
};

export default Courses;
