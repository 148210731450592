import { Row } from 'antd';
import React, { Fragment } from 'react';
import { filterImagesFluid } from '../../../util/filterImages';
import { gray100 } from '../../_Styles';
import Section from '../Section/Section';
import CourseItem from './courseItem';

const CoursesList = ({ allImages, allCourses, allInfoSections }) => {
  console.log(allImages);
  console.log(allCourses.edges);

  const courses = allCourses.edges;

  const images = allImages.edges;

  console.log();
  const { title, subtitle } = allInfoSections.edges[1].node;

  // console.log(imageCourse);
  return (
    <Fragment>
      <Section
        title={title}
        name="capacitaciones"
        background={gray100}
        subtitle={subtitle}
      >
        <Row
          type="flex"
          align="top"
          justify="center"
          gutter={[16, 16]}
          style={{
            maxWidth: 1200,
            margin: '0 auto',
            marginTop: '15vh',
          }}
        >
          {courses.map((course) => {
            console.log(course.node.slung);

            const { id, slung, name, shortDescription } = course.node;

            const imageCourse = filterImagesFluid(
              images,
              `${slung}-main`,
            )[0].node.localImage.childImageSharp.fluid;

            return (
              <CourseItem
                key={id}
                image={imageCourse}
                title={name}
                description={shortDescription}
                slung={slung}
              />
            );
          })}
        </Row>
      </Section>
    </Fragment>
  );
};

export default CoursesList;
