import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import Sections from '../components/sections';
import Hero from '../components/sharded/Hero';
import Nav from '../components/sharded/Navbar';
import { FirebaseContext } from '../firebase';
import firebase from '../firebase';
import '../less/antd_customStyles.less';
import '../scss/styles.scss';

const Home = ({ data }) => {
  const [open, setOpen] = useState(false);

  const {
    allImages,
    allHero,
    allConfigs,
    allSections,
    allInfoSections,
    allModalities,
    allFeatures,
    allTestimonies,
    allServices,
    allCourses,
  } = data;

  console.log(allImages);

  return (
    <Layout allConfig={allConfigs}>
      <FirebaseContext.Provider
        value={{
          firebase,
        }}
      >
        <Nav
          open={open}
          setOpen={setOpen}
          allConfigs={allConfigs}
        ></Nav>
        <Hero
          allImages={allImages}
          allHero={allHero}
          allConfig={allConfigs}
        />
        <Sections
          allSections={allSections}
          allInfoSections={allInfoSections}
          modality={allModalities}
          // pedagogy={allInfoSections}
          allFeatures={allFeatures}
          allTestimonies={allTestimonies}
          allServices={allServices}
          allImages={allImages}
          allCourses={allCourses}
        />
      </FirebaseContext.Provider>
    </Layout>
  );
};
export const query = graphql`
  {
    allCourses {
      edges {
        node {
          id
          slung
          name
          shortDescription
        }
      }
    }
    allServices {
      edges {
        node {
          id
          description
          title
          icon
        }
      }
    }
    allTestimonies {
      edges {
        node {
          testimonie
          profession
          photo
          id
          fullName
        }
      }
    }
    allFeatures {
      edges {
        node {
          id
          title
          description
          icon
        }
      }
    }
    allModalities {
      edges {
        slide: node {
          title
          id
          description
        }
      }
    }
    allInfoSections {
      edges {
        node {
          title
          subtitle
          id
          description
        }
      }
    }
    allImages {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          alt
          id
        }
      }
    }
    allHero {
      nodes {
        description
        textButton
        title
        image
      }
    }
    allConfigs {
      edges {
        node {
          menuBackground
          about
          logoDark
          logoLight
          menu {
            url
            itemName
          }
          socialNetwork {
            icon
            name
            url
          }
        }
      }
    }
  }
`;

export default Home;
