import React, { Fragment } from 'react';
import { Link } from 'react-scroll';
import { filterImagesFluid } from '../../../util/filterImages';
import { Button } from '../../UIComponents/Button';
import {
  ContentWrapper,
  HeroWrapper,
  HeroShape,
  ImgContainer,
  ImageCircle,
  Content,
  Img,
} from './styled';

const Hero = ({ allImages, allHero, allConfig }) => {
  console.log(allHero, allConfig, allImages);

  const imgUrl = filterImagesFluid(allImages.edges, 'hero')[0].node
    .localImage.childImageSharp.fluid;
  // const fluid = imgUrl[0].node.localImage.childImageSharp.fluid;

  return (
    <Fragment>
      <HeroWrapper id="home">
        <HeroShape />
        <ImgContainer style={{ marginTop: '4rem' }}>
          <ImageCircle>{<Img fluid={imgUrl} fadeIn />}</ImageCircle>
        </ImgContainer>
        <ContentWrapper>
          <Content>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: allHero.nodes[0].description,
              }}
            ></div>
            {/* {allHero.nodes[0].description} */}

            <Link
              activeClass="active"
              to="metodologia"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              {/* <span>Metodologia</span> */}
              <Button
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {allHero.nodes[0].textButton}
              </Button>
            </Link>
          </Content>
        </ContentWrapper>
      </HeroWrapper>
    </Fragment>
  );
};

export default Hero;
