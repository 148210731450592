import styled from '@emotion/styled';
import Image from 'gatsby-image';
export const Img = styled(Image)`
  width: 280px;
  height: 280px;
  @media (max-width: 319px) {
    width: 240px;
    height: 240px;
  }
  @media (min-width: 669px) {
    width: 300px;
    height: 300px;
  }
  @media (min-width: 800px) {
    width: 300px;
    height: 300px;
  }
  @media (min-width: 900px) {
    width: 450px;
    height: 450px;
  }
`;

export const HeroWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr);
  grid-template-areas: 'img content';

  position: relative;
  color: white;

  min-height: 100vh;
  height: 100%;
  padding: 2rem;
  /* background: #222; */
  @media (max-width: 900px) {
    grid-template-columns: minmax(320px, 1fr);
    padding: 1rem;
    grid-template-areas: 'img' 'content';
    height: 100%;
    min-height: 100vh;
    padding-bottom: 2rem;
  }
`;

export const HeroShape = styled.div`
  position: absolute;
  top: 0;
  clip-path: polygon(0 0%, 60% 0%, 20% 100%, 0% 100%);
  min-height: 100vh;
  width: 100vw;
  background: #222;
  z-index: -300;
  transition: all 300ms ease-in-out;
  height: 100%;

  padding: 2rem;

  @media (max-width: 900px) {
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
    padding: 2rem;
    min-height: 100vh;
  }
`;

export const ImgContainer = styled.div`
  grid-area: img;
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  z-index: -100;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  transition: all 300ms ease-in-out;
  img {
    width: 450px;
    transition: all 300ms ease-in-out;

    @media (max-width: 768px) {
      max-width: 200px;
      transition: all 300ms ease-in-out;
    }
    @media (max-width: 900px) {
      max-width: 300px;
      justify-content: center;
    }
    @media (max-width: 320px) {
      max-width: 280px;
      justify-content: center;
    }
  }
  @media (max-width: 900px) {
    transition: all 300ms ease-in-out;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    margin-top: 2rem 0;
  }
  @media (max-width: 320px) {
    transition: all 300ms ease-in-out;
    position: relative;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 3rem;
  }
`;

export const ImageCircle = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;

  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  transition: all 300ms ease-in-out;
  @media (max-width: 768px) {
    /* margin-top:2rem; */
    position: relative;
    max-width: 300px;
    left: 0;
    transition: all 300ms ease-in-out;
  }
  @media (max-width: 900px) {
    transition: all 300ms ease-in-out;
    position: relative;
    max-width: 380px;

    left: 0;
  }
  @media (max-width: 319px) {
    margin-top: 2rem;
    position: relative;
    max-width: 280px;
    max-height: 280px;
    transition: all 300ms ease-in-out;
  }
`;
export const ContentWrapper = styled.div`
  grid-area: content;
  color: inherit;
  /* background: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 900px) {
    color: inherit;
    justify-content: center;
    margin-top: 1.6rem;
  }
`;

export const Content = styled.div`
  max-width: 400px;
  width: 100%;
  min-width: 260px;
  color: white;

  @media (min-width: 320px) {
    color: white;
    width: 270px;
  }
  @media (min-width: 900px) {
    color: #333;
    width: 100%;
    max-width: 420px;
    min-width: 200px;
  }
`;
