
import React, { Fragment } from 'react';
import { Button } from '../UIComponents/Button';
import "../../scss/_card.scss";
import Img from "gatsby-image";
import { Link } from 'gatsby';
const Card = ({ image, title, description, slung }) => {
    console.log(slung);
    return (
        <Fragment>
            <div className="card_wrapper">
                <div className="mask-image"></div>
                <Img className="card_image" fluid={image} />
                <div className="card_body">
                    <h2 className="card_title">{title}</h2>
                    <p className="card_description">
                        {description}

                    </p>
                    <Link to={`cursos/${slung}`} style={{ width: '100%' }}>
                        <Button style={{ width: "100%" }} className="card_button">Más información</Button>

                    </Link>

                </div>
            </div>

        </Fragment>
    );
};

export default Card;
