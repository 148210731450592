import { primary } from '../../_Styles';
import styled from '@emotion/styled';

export const NavWrapper = styled.div`
  padding: 1rem;
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 10000;
  padding: 1rem 3rem;
  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

export const ListItem = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 700px;
  margin-top: 2rem;
  transition: all 400ms ease-in-out;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  font-family: 'Bebas Neue', cursive;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  list-style: none;
  color: white;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  &::after {
    content: '';
    height: 2px;
    width: 0;
    background: ${primary};
  }
  &:hover {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &::after {
      content: '';
      height: 2px;
      width: 100%;
      background: white;
    }
  }
  a.active {
    background: white;
    color: ${primary};
    padding: 0 2rem;
  }
  span {
    font-family: 'Bebas Neue', cursive;
  }
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;
