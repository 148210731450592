import React, { Fragment, useEffect } from 'react';
import BurguerMenu from './BurguerMenu';

import Logo from '../../icons/Logo';

import { Menu } from './Menu';

import { Link, Events, scrollSpy } from 'react-scroll';
import { NavWrapper, ListItem, Item } from './style';

import { graphql, useStaticQuery } from 'gatsby';

const Nav = ({ open, setOpen, allConfigs }) => {
  // const data = useStaticQuery(graphql`
  //   query MyQuery {
  //     nav: allDatoCmsHome {
  //       data: edges {
  //         node {
  //           menu {
  //             id
  //             name
  //           }
  //           logotipo {
  //             alt
  //             url
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const { menu, logotipo } = data.nav.data[0].node;
  // console.log(logotipo);

  const handleClick = (open) => {
    setOpen(!open);
  };

  useEffect(() => {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }, []);

  const handleSetActive = (to) => {
    // console.log(to);
    // setOpen(!open);
  };
  const { menu } = allConfigs.edges[0].node;
  const { logoLight } = allConfigs.edges[0].node;
  console.log(logoLight);

  return (
    <Fragment>
      <NavWrapper>
        {!open && (
          <Link
            activeClass="active"
            to="home"
            onClick={handleClick}
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onSetActive={handleSetActive}
          >
            <img
              style={{ width: 250, marginTop: '.8rem' }}
              src={logoLight}
              // alt={logotipo.alt}
            />
            {/* <Logo color="white"></Logo> */}
          </Link>
        )}
        <BurguerMenu
          open={open}
          handleClick={handleClick}
        ></BurguerMenu>
      </NavWrapper>
      {open && (
        <Menu>
          <Link
            activeClass="active"
            to="home"
            onClick={handleClick}
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onSetActive={handleSetActive}
          >
            <img
              style={{ width: 300 }}
              src={logoLight}
              // alt={logotipo.alt}
            />
            {/* <Logo color="white"></Logo> */}
          </Link>
          <ListItem>
            {menu.map((item) => {
              console.log(item);
              return (
                <Item key={item.itemName}>
                  <Link
                    activeClass="active"
                    to={item.url}
                    onClick={handleClick}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onSetActive={handleSetActive}
                  >
                    <span>{item.itemName}</span>
                  </Link>
                </Item>
              );
            })}
          </ListItem>
        </Menu>
      )}
    </Fragment>
  );
};

export default Nav;
